.green {
  background-color: #ddebd7;
  background-image: url(../../assets/bg/vectorization-green.svg);
  background-position: -150px -25px;
  background-repeat: no-repeat;
  background-size: 100%;
  overflow: hidden;
  padding: 5% 10% 4%;
  color: #1f3b21;
}

.green h5 {
  line-height: 52px;
  letter-spacing: -0.005em;
  color: #1f3b21;
  font-weight: 400;
  font-size: 48px;
}

button.d-block.mt-5.m-auto {
  display: inline-block;
  padding: 20px 86px 20px 30px;
  position: relative;
  overflow: hidden;
  line-height: 1;
  background: transparent;
  vertical-align: middle;
  margin-bottom: 20px;
  box-shadow: none;
  z-index: 1;
  text-decoration: none;
  border: 1px solid #1f3b21;
  border-radius: 30px;
}
button.d-block.mt-5.m-auto:hover {
  background-color: #1f3b21;
}
button.d-block.mt-5.m-auto span {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #1f3b21;
  color: #ddebd7;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  align-content: center;
}
