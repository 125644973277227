.Verification {
  background-color: #6b3e3a;
  background-image: url(../../assets/bg/vectorization.svg);
  background-position: 150px -25px;
  background-repeat: no-repeat;
  background-size: 100%;
  overflow-x: hidden;
}
.Verification-container {
  padding: 5% 10%;
}
.Verification-container h2 {
  line-height: 24x;
  letter-spacing: -0.005em;
  margin-bottom: 0;
  color: #fafafa;
  font-weight: 400;
  font-size: 2rem;
}
.Verification-header {
  padding-right: 240px;
}
.Verification-cards img {
  width: 240px;
}
.Verification-cards > * {
  color: #fafafa;
}
.Verification-card-number h3 {
  font-size: 80px;
}
a.verificationDownloads {
  font-size: 12px;
  color: #fafafa;
  text-align: center;
}
.fa-file-pdf{
  height: 2em;
}
/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .Verification-header {
    padding: 0;
    margin-bottom: 2em;
  }
}
