.navbar-logo {
  margin: 0 1%;
  aspect-ratio: 1;
  width: 3em;
  margin-left: 40px;
}

.header {
  position: absolute;
  top: 0;
  background-color: rgba(31, 59, 33, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 1000;
  padding: 0;
  width: 100%;
  height: 10%;
  /* background-color: rgba(221, 232, 207); */
}

.navbar-brand {
  font-family: GTFlexa;
  color: #232323 !important;
  font-size: 20px !important;
}
.nav-body {
  justify-content: end;
}
.nav-link {
  font-family: Inter;
  color: #1e1e1e !important;
}
.navbar-nav a {
  padding: 0 1%;
  color: #1e1e1e;
  text-decoration: none;
  align-self: center;
}
.nav-item.dropdown {
  align-self: center;
}

.headerBtn {
  height: 40px;
  padding: 10px 24px;
  border-radius: 6px;
  border: 1px solid rgba(31, 59, 33, 0.4);
  outline: 0;
  background: var(--primaryColor);
  border-color: var(--primaryColor);
  font-size: 16px;
  line-height: 18px;
  margin-right: 40px;
}
.headerBtn:hover {
  background-color: var(--primaryButtonColorHover);
  border-color: var(--primaryButtonColorHover);
}
.dropdown-menu.show,
.offcanvas.show {
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.01);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-top: none;
  width: 100vw;
  padding: 0;
  top: 0;
  left: 0;
  height: 100vh;
  border: none;
  margin: 0;
  background: var(--bg-color);
  background-image: url(../../assets/bg/colorkit.svg);
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: 50% 40%;
  border-radius: 0;
  position: fixed;
  animation: slide-in 500ms;
  animation-fill-mode: backwards;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.offcanvas.show {
}
.offcanvas-backdrop {
  background-color: transparent;
}
.dropdown-menu.slide-out {
  animation: slide-out 500ms;
}

.header-close-btn {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 1.5em;
}
.header-close-btn:focus,
.header-close-btn:hover {
  transform: scale(125%);
  cursor: pointer;
  transition: 0.5s;
}
.nav-links {
  margin: 4em 3em 0;
}

.dropdown-menu.show .row .col-md-4 ul {
  padding: 0.5em 0 0;
}
.dropdown-menu.show .row .col-md-4 li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.dropdown-menu.show .row .dropdown-item {
  padding-left: 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: rgb(221, 235, 215, 0.5);
  transform: scale(102%);
  text-shadow: -1px 1px 1px #9c9c9c;
}
.header-banner {
  height: 60%;
  background-repeat: no-repeat;
  background-size: cover;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:hover,
.navbar-toggler:active {
  border: none;
  box-shadow: none;
}
@keyframes slide-in {
  from {
    transform: translateY(-100%);
    opacity: 0.25;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0%);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0.25;
  }
}
@keyframes slide-in-left {
  from {
    transform: translateX(-100%);
    opacity: 0.25;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slide-out-left {
  from {
    transform: translateX(0%);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0.25;
  }
}
/* Extra small devices (phones, 600px and down) */
@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: fixed;
  }
}
@media (max-width: 768px) {
  .header {
    overflow-x: hidden;
  }
  .offcanvas-body {
    display: flex;
  }
  .nav-body {
    justify-content: center;
    gap: 1rem;
  }
  .navbar-logo,
  .navbar-brand {
    margin: 0;
  }
  .navbar .container-fluid {
    justify-content: space-around;
  }
}
