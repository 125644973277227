.red {
  background-color: #6b3e3a;
  background-image: url(../../assets/bg/vectorization.svg);
  background-position: -250px -55px;
  background-repeat: no-repeat;
  background-size: 100%;
  overflow: hidden;
  padding: 5% 10%;
  color: #fafafa;
}
.red h5 {
  line-height: 24x;
  letter-spacing: -0.005em;

  font-weight: 400;
  font-size: 2rem;
}
