.Mechanism {
  background-color: #ddebd7;
  background-image: url(../../assets/bg/vectorization-green.svg);
  background-position: -150px -25px;
  background-repeat: no-repeat;
  background-size: 100%;
  overflow-x: hidden;
}
.Mechanism-container {
  padding: 5% 10% 0;
}
.Mechanism-container h2 {
  line-height: 52px;
  letter-spacing: -0.005em;
  margin-bottom: 0;
  color: #1f3b21;
  font-weight: 400;
  font-size: 48px;
}
.Mechanism-header {
  padding-right: 240px;
}
.Mechanism-cards img {
  width: 100px;
}
.Mechanism-cards > * {
  color: #1f3b21;
}
.Mechanism-card-number h3 {
  font-size: 80px;
}
/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .Mechanism-header {
    padding: 0;
    margin-bottom: 1em;
  }
}
