.BannerCarousel {
  overflow-x: hidden;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(31, 59, 33, 0.4);
}
.BannerImg {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}
.carousel-caption {
  bottom: 8%;
}
.carousel-caption h3 {
  font-family: GTFlexa;
  font-size: 5em;
}
.carousel-caption p {
  font-family: Inter;
  font-size: 1.5em;
}
.carousel-item {
  transition: transform 2.6s ease-in-out;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  transition: opacity 0s 2.6s;
}

.BannerBtn {
  height: 48px;
  padding: 10px 24px;
  border-radius: 6px;
  border: 1px solid rgba(31, 59, 33, 0.4);
  outline: 0;
  background: var(--primaryButtonColor);
  border-color: var(--primaryButtonColor);
  font-size: 16px;
  line-height: 18px;
  margin-right: 40px;
}
.BannerBtn:hover {
  background-color: var(--primaryButtonColorHover);
  border-color: var(--primaryButtonColorHover);
  /* background-color: #fefefe; */
  transition: 1s;
}

.center {
  margin: 10% auto 0 auto;
}
.mouse-down {
  width: 20px;
  height: 30px;
  border: 2px solid #fff;
  border-radius: 20px;
}
.bullet {
  width: 5px;
  height: 5px;
  background: #fff;
  margin: auto auto;
  border-radius: 8px;
  position: relative;

  -webkit-animation: scrollDownUp 3s infinite; /* Chrome, Safari, Opera */
  animation: scrollDownUp 3s infinite;
}
/* Chrome, Safari, Opera */
@-webkit-keyframes scrollDownUp {
  0% {
    top: 5px;
  }
  50% {
    top: 20px;
  }
  100% {
    top: 5px;
  }
}

/* Standard syntax */
@keyframes scrollDownUp {
  0% {
    top: 5px;
  }
  50% {
    top: 20px;
  }
  100% {
    top: 5px;
  }
}
/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .carousel-caption h3 {
    font-size: 3.5em;
  }
  .BannerBtn {
    margin: 0;
  }
  .svg-inline--fa {
    vertical-align: middle;
  }
}
