@font-face {
  font-family: GTFlexa;
  src: url(assets/typeface/GT-Flexa/GT-Flexa-Regular.otf);
}
@font-face {
  font-family: Inter;
  src: url(assets/typeface/Inter-Regular.otf);
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
  font-family: Inter;
  -webkit-font-smoothing: antialiased;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: GTFlexa;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
#root,
#__next {
  isolation: isolate;
}
:root {
  -webkit-text-size-adjust: 100%;
  --darkGreen100: #1f3b21;
  --bg-color: #ddebd7;
  color: var(--darkGreen100);
  font-size: 16px;
  box-sizing: border-box;
  /* background-color: #ddebd7; */
  /* white-space: pre-wrap; */
}

/* Works on Firefox */
body {
  scrollbar-width: thin;
  scrollbar-color: var(--linkLightPressed), var(--bg-color);
}
/* Works on Chrome, Edge, and Safari */
body::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: var(--bg-color); /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: var(--linkLightPressed); /* color of the scroll thumb */
  /* border-radius: 20px; roundness of the scroll thumb */
}

.App {
  text-align: center;
}
